import { debounce } from "lodash";
import React, { useState, useCallback } from "react";
import { useCombobox } from "downshift";
import axios from "axios";

function createHtml(string) {
  return { __html: string };
}

export default function SearchSchool({ query, setSelected }) {
  const [inputItems, setInputItems] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [placeHolder, setPlaceHolder] = useState("Sök skola...");

  const url = axios.create({
    baseURL: "https://snxwuitild.execute-api.eu-west-1.amazonaws.com",
  });

  const getApiSuggestions = (word) => {
    url
      .get(`/prod?q=${word}&index=allautbildningarnuautocomplete`)
      .then((response) => {
        setInputItems(response.data);
      })
      .catch((error) => {
        return error;
      });
  };

  const debouncedSave = useCallback(
    debounce((inputValue) => getApiSuggestions(inputValue), 150),
    []
  );

  const {
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    highlightedIndex,
  } = useCombobox({
    items: inputItems,
    selectedItem,
    inputValue,
    onInputValueChange({ inputValue }) {
      setInputValue(inputValue);

      if (!inputValue) {
        setInputItems([]);
      }

      if (inputValue) {
        debouncedSave(inputValue);
      }
    },
    onSelectedItemChange({ selectedItem }) {
      if (selectedItem) {
        console.log(setSelected);
        setSelectedItem(selectedItem);
        setSelected(selectedItem);
        setPlaceHolder(selectedItem.name);
      }
    },
    itemToString: (item) => "",
  });

  return (
    <div className="max-w-3xl">
      <div className="flex flex-row">
        <div className="flex-1 rounded-none  bg-white ">
          <div className="sm:relative rounded-none" {...getComboboxProps()}>
            <input
              aria-label="Autocomplete search"
              
              {...getInputProps({
                type: "search",
                placeholder: placeHolder,
                id: "search-desktop",
                className:
                  (selectedItem
                    ? "text-gray-900"
                    : "text-gray-600") +
                  " block w-full p-[10px] font-normal border-2 border-gray-300 align-baseline focus:ring-indigo-500 focus:border-indigo-500",
              })}
            />
            <div
              aria-label="wrapper for searchfield"
              className="z-50 bg-white absolute w-full"
              {...getMenuProps()}
            >
              {inputItems.length > 0 && (
                <div className="w-full border-gray-200 border-2 flex items-center">
                  <div className="w-full border-gray-200">
                    <div className="pt-2 pb-2 z-50 w-full bg-gray-200">
                      <p className="px-4 block text-sm text-gray-700">
                        Sökförslag
                      </p>
                    </div>
                    {inputItems.map((item, index) => (
                      <div
                        key={item.label}
                        {...getItemProps({ item, index })}
                        className={
                          highlightedIndex === index ? "bg-gray-100" : ""
                        }
                      >
                        <div
                          className={
                            inputItems.length !== index + 1 ? "border-b" : ""
                          }
                        >
                          <div className="pt-2 pb-2 z-50 w-full">
                            <p
                              className="px-5 block"
                              dangerouslySetInnerHTML={createHtml(
                                item.transformed
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
