import { ArrowRightIcon } from "@heroicons/react/outline";
import React, { useContext, useEffect, useState } from "react";
import SearchSchool from "../../components/searchSchool";
import { AuthContext } from "../../contexts/authContext";
import { authenticatedRequest } from "../../libs/authenticatedRequest";

export default function CreateProject() {
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [introStage, setIntroStage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(null);

  const auth = useContext(AuthContext);
  const API_URL = "https://324xer16pj.execute-api.eu-west-1.amazonaws.com";

  async function createProject() {
    setLoading(true);
    const project = await authenticatedRequest(auth).post(
      API_URL + "/projects",
      { name }
    );
    setLoading(false);
  }

  return (
    <div className="md:pl-64 flex flex-col flex-1">
      <main className="flex-1">
        <div className="py-6">
          <div className="max-w-4xl px-4 sm:px-6 md:px-8 mb-8">
            <h1 className="text-2xl font-semibold text-gray-900">
              Skapa projekt
            </h1>
            <p className="mt-5">
              Välj ett namn på ditt projekt. Du kommer sedan få välja skola och
              utbildningar för projektet.
            </p>

            <div className="relative mt-10">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-300" />
              </div>
            </div>

            <div className="pt-8">
              <div className="max-w-sm">
                <label
                  htmlFor="first-name"
                  className="block text-md font-medium text-gray-700"
                >
                  Namn:
                </label>
                <div className="mt-1">
                  <input
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    name="first-name"
                    id="first-name"
                    placeholder="Projekt exempelskola..."
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full border-2 border-gray-300 p-2"
                  />
                </div>
              </div>

              <div className="mt-10 max-w-2xl">
                <span className="block text-md font-medium text-gray-700">
                  Välj skola:
                </span>
                <SearchSchool setSelected={setSelectedSchool} />

                {selectedSchool ? (
                  <div className="mt-5">
                    <span className="text-md font-semibold mt-10">
                      Vald skola:
                    </span>
                    <span className="underline ml-2">
                      {selectedSchool.name}
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <button
              type="button"
              onClick={() => createProject()}
              className="mt-10 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              Gå vidare {loading ? " ... Laddar" : ""}
              <ArrowRightIcon
                className="ml-3 -mr-1 h-5 w-5"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
      </main>
    </div>
  );
}
