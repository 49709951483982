import { Fragment, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CalendarIcon,
  ChartBarIcon,
  CogIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  LogoutIcon,
  MenuIcon,
  QuestionMarkCircleIcon,
  SupportIcon,
  UsersIcon,
  XIcon,
} from "@heroicons/react/outline";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../contexts/authContext";

const navigation = [
  { name: "Hem", href: "/", icon: HomeIcon },
  {
    name: "Inställningar",
    href: "/settings",
    icon: CogIcon,
    current: false,
  },
  {
    name: "Support",
    href: "/support",
    icon: QuestionMarkCircleIcon,
    current: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navigation({ sidebarOpen, setSidebarOpen }) {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useContext(AuthContext);

  const path = location.pathname;

  function signOutClicked() {
    auth.signOut();
    navigate("/");
  }

  function navigateRoute(route) {
    navigate(route);
    setSidebarOpen(false);
  }
  return (
    <div>
      <div className="w-full bg-blue-500 px-4 sm:px-6 lg:px-8 shadow-lg">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <div className="flex-shrink-0 ">
              <div className=" inline">
                <svg
                  className="text-white stroke-current  rounded-full inline"
                  xmlns="http://www.w3.org/2000/svg"
                  width={"30"}
                  height={"30"}
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M0 0h24v24H0z" stroke="none" />
                  <rect x="3" y="12" width="6" height="8" rx="1" />
                  <rect x="9" y="8" width="6" height="12" rx="1" />
                  <rect x="15" y="4" width="6" height="16" rx="1" />
                  <path d="M4 20h14" />
                </svg>
              </div>
              <span className="font-bold text-lg text-white ml-1">
                edtraction
              </span>
            </div>
          </div>
          <div className="sm:ml-6">
            <div className="flex space-x-4 justify-end ">
              <div className="sticky top-0 z-10 md:hidden pl-1 sm:pl-3 text-white">
                <button
                  type="button"
                  className="-ml-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-white-500 hover:text-white-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => setSidebarOpen(true)}
                >
                  <span className="sr-only">Open sidebar</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
            </div>
          </div>
        </div>
      </div>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 flex z-40">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                    <div className="flex-shrink-0 flex items-center px-4">
                      <svg
                        className="text-primary-500 stroke-current mr-1"
                        xmlns="http://www.w3.org/2000/svg"
                        width={"30"}
                        height={"30"}
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M0 0h24v24H0z" stroke="none" />
                        <rect x="3" y="12" width="6" height="8" rx="1" />
                        <rect x="9" y="8" width="6" height="12" rx="1" />
                        <rect x="15" y="4" width="6" height="16" rx="1" />
                        <path d="M4 20h14" />
                      </svg>
                      <span className="font-medium text-xl text-gray-800">
                        edtraction
                      </span>
                    </div>
                    <nav className="block mt-5 px-2 space-y-1">
                      {navigation.map((item) => (
                        <span
                          key={item.name}
                          onClick={() => navigateRoute(item.href)}
                          className={classNames(
                            item.current
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md cursor-pointer"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-gray-500"
                                : "text-gray-400 group-hover:text-gray-500",
                              "mr-4 flex-shrink-0 h-6 w-6"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </span>
                      ))}
                      <span
                        onClick={() => signOutClicked()}
                        className={
                          "cursor-pointer text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                        }
                      >
                        <LogoutIcon className="text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6" />
                        Logga ut
                      </span>
                    </nav>
                  </div>
                  {/* <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                    <div className="flex items-center">
                      <div>
                        <img
                          className="inline-block h-10 w-10 rounded-full"
                          src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                          alt=""
                        />
                      </div>
                      <div className="ml-3">
                        <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">
                          Tom Cook
                        </p>
                        <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                          View profile
                        </p>
                      </div>
                    </div>
                  </div> */}
                </Dialog.Panel>
              </Transition.Child>
              <div className="flex-shrink-0 w-14">
                {/* Force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-16">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white pt-5">
            <div className="flex-1 flex flex-col pb-4 overflow-y-auto">
              <nav className=" flex-1 px-2 pt-0 bg-white space-y-1">
                {navigation.map((item) => (
                  <span
                    key={item.name}
                    onClick={() => navigateRoute(item.href)}
                    className={classNames(
                      item.href === path
                        ? "bg-gray-100 text-gray-900"
                        : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                      "group flex items-center px-2 py-2 text-sm font-medium rounded-md cursor-pointer"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.href === path
                          ? "text-gray-500"
                          : "text-gray-400 group-hover:text-gray-500",
                        "mr-3 flex-shrink-0 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </span>
                ))}
                <span
                  onClick={() => signOutClicked()}
                  className={
                    "cursor-pointer text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                  }
                >
                  <LogoutIcon className="text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6" />
                  Logga ut
                </span>
              </nav>
            </div>
            {/* <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
              <div className="flex items-center">
                <div>
                  <img
                    className="inline-block h-9 w-9 rounded-full"
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                    Tom Cook
                  </p>
                  <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                    View profile
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
