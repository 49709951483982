import {
  ArrowRightIcon,
  InformationCircleIcon,
  MenuIcon,
  PlusCircleIcon,
} from "@heroicons/react/outline";
import { map } from "lodash";
import React, { useContext, useEffect, useState } from "react";

import { useNavigate, useOutletContext } from "react-router-dom";
import SearchSchool from "../components/searchSchool";

import { AuthContext } from "../contexts/authContext";
import { authenticatedRequest } from "../libs/authenticatedRequest";
import IntroWrapper from "./intro/intro-wrapper";

export default function Home() {
  const navigate = useNavigate();
  const [, setSidebarOpen] = useOutletContext();
  const [selectedSchool, setSelectedSchool] = useState(null);
  const auth = useContext(AuthContext);
  const API_URL = "https://324xer16pj.execute-api.eu-west-1.amazonaws.com";
  const [user, setUser] = useState(null);

  useEffect(() => {
    async function getUser() {
      const user = await authenticatedRequest(auth).get(API_URL + "/me");
      setUser(user);
    }
    getUser();
  }, [auth]);

  function signOutClicked() {
    auth.signOut();
    navigate("/");
  }

  function changePasswordClicked() {
    navigate("changepassword");
  }

  return (
    <div className="md:pl-64 flex flex-col flex-1">
      <main className="flex-1">
        <div className="py-6">
          <div className="max-w-4xl px-4 sm:px-6 md:px-8 mb-8">
            <h1 className="text-2xl font-semibold text-gray-900">
              Välkommen till Edtraction!
            </h1>
            <p className="mt-5">
              Edtraction hjälper dig nå ut till relevanta studenter för din
              skola eller utbildning.
            </p>
            <p className="mt-5">
              Nedan kan du se de projekt du skapat. Om du inte skapat ett
              projekt ännu kan du sätta igång genom att klicka på{" "}
              <span className="font-bold">"Skapa ett nytt projekt"</span> nedan.
            </p>
            <p className="bg-primary-100 p-2 rounded-r-full mt-5">
              <InformationCircleIcon className="mx-auto h-6 w-6 text-gray-600 font-light inline mr-3 -mt-1" />
              Ett projekt låter dig välja en skola och valfritt antal
              utbildningar du vill marknadsföra.
            </p>

            {user && user.projects.length > 0 ? (
              <>
                <div className="relative mt-10 mb-10">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-start">
                    <span className="pr-2 bg-white text-sm text-gray-500">
                      Dina projekt
                    </span>
                  </div>
                </div>
                {user.projects.map((p, i) => (
                  <button
                    type="button"
                    onClick={() => navigate("/projects/create")}
                    className="relative bg-emerald-700 border-0 w-60 h-40 text-white rounded-lg text-center hover:bg-emerald-600 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-indigo-500 mr-5"
                  >
                    <span className="mt-2 block text-xl font-medium text-white">
                      {p.name}
                    </span>
                  </button>
                ))}
              </>
            ) : (
              ""
            )}

            <div className="relative mt-10 mb-10">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-start">
                <span className="pr-2 bg-white text-sm text-gray-500">
                  Skapa nytt
                </span>
              </div>
            </div>

            <button
              type="button"
              onClick={() => navigate("/projects/create")}
              className="relative border-2 mt-0 border-gray-300 border-dashed w-60 h-40 rounded-lg text-center hover:border-gray-400 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <PlusCircleIcon
                className="mx-auto h-12 w-12 text-gray-400 font-light"
                strokeWidth={1}
              />
              <span className="mt-2 block text-sm font-medium text-gray-800">
                Skapa ett nytt projekt
              </span>
            </button>
          </div>

          {/* Intro Component */}
          {/* <IntroWrapper /> */}

          {/* <div className="max-w-4xl px-4 sm:px-6 md:px-8">
            <p className="">{JSON.stringify(auth.attrInfo, null, 2)}</p>
          </div> */}
        </div>
      </main>
    </div>
  );
}
