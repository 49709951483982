import React from "react";
import "./App.css";

import { HashRouter as Router, Route, Routes } from "react-router-dom";

import AuthProvider, {
  AuthIsSignedIn,
  AuthIsNotSignedIn,
} from "./contexts/authContext";

import SignIn from "./routes/auth/signIn";
import SignUp from "./routes/auth/signUp";
import VerifyCode from "./routes/auth/verify";
import RequestCode from "./routes/auth/requestCode";
import ForgotPassword from "./routes/auth/forgotPassword";
import ChangePassword from "./routes/auth/changePassword";
import Home from "./routes/home";
import Portal from "./routes/Portal";
import Settings from "./routes/settings";
import Support from "./routes/support/support";
import CreateProject from "./routes/project/create";

const SignInRoute: React.FunctionComponent = () => (
  <Router>
    <Routes>
      <Route path="/signup" element={<SignUp />} />
      <Route path="/verify" element={<VerifyCode />} />
      <Route path="/requestcode" element={<RequestCode />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/" element={<SignIn />} />
    </Routes>
  </Router>
);

const MainRoute: React.FunctionComponent = () => (
  <Router>
    <Routes>
      <Route path="/changepassword" element={<ChangePassword />} />

      <Route element={<Portal />}>
        <Route path="/settings" element={<Settings />} />
        <Route path="/support" element={<Support />} />
        <Route path="/projects/create" element={<CreateProject />} />
        <Route path="/" element={<Home />} />
      </Route>
    </Routes>
  </Router>
);

const App: React.FunctionComponent = () => (
  <AuthProvider>
    <AuthIsSignedIn>
      <MainRoute />
    </AuthIsSignedIn>
    <AuthIsNotSignedIn>
      <SignInRoute />
    </AuthIsNotSignedIn>
  </AuthProvider>
);

export default App;
