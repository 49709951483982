import { ArrowRightIcon, MenuIcon } from "@heroicons/react/outline";
import React, { useContext, useState } from "react";

import { useNavigate, useOutletContext } from "react-router-dom";

import { AuthContext } from "../../contexts/authContext";

export default function Support() {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  return (
    <div className="md:pl-64 flex flex-col flex-1">
      <main className="flex-1">
        <div className="py-6">
          {/* <div className="max-w-4xl px-4 sm:px-6 md:px-8 mb-8">
            <h1 className="text-2xl font-semibold text-gray-900">Hem</h1>
          </div> */}

          {/* Intro Component */}
          <p>Hej</p>
          {/* <div className="max-w-4xl px-4 sm:px-6 md:px-8">
            <p className="">{JSON.stringify(auth.attrInfo, null, 2)}</p>
          </div> */}
        </div>
      </main>
    </div>
  );
}
